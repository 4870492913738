<template>
  <NaiveConfigProvider>
    <NMessageProvider placement="bottom-right">
      <NDialogProvider>
        <SpeedInsights />
        <NuxtLoadingIndicator />
        <WhatsNewDialog />
        <NLayout has-sider class="h-screen">
          <NLayoutSider
            bordered
            class="!z-10"
            show-trigger
            :collapsed="navIsCollapsed"
            collapse-mode="width"
            @collapse="navIsCollapsed = true"
            @expand="navIsCollapsed = false"
          >
            <div class="sticky top-0">
              <NFlex vertical size="large" class="">
                <div class="p-2 py-4" :class="{ 'px-10': !navIsCollapsed }">
                  <BrandDisplay :collapsed="navIsCollapsed" />
                </div>
                <NMenu
                  :value="activeMenuOption"
                  :options="menuOptions"
                  :indent="8"
                  :root-indent="16"
                  @update:value="performMenuAction"
                />
              </NFlex>
            </div>

            <NModal
              :show="state === 'newApplicationModal'"
              preset="card"
              title="New application"
              class="w-full max-w-lg"
              @update:show="transitionTo('idle')"
            >
              <NForm
                ref="newAppFormRef"
                :rules="newAppFormRules"
                :model="newApp"
                :disabled="state !== 'newApplicationModal'"
                @submit.prevent="createNewApplicationAndRedirect"
              >
                <NFlex vertical>
                  <NFormItem
                    label="Application name"
                    path="label"
                    :label-props="{
                      for: 'newApp.label',
                    } /* links the label to the input */"
                    required
                  >
                    <NInput
                      v-model:value="newApp.label"
                      type="text"
                      :input-props="{ id: 'newApp.label' }"
                    />
                  </NFormItem>
                  <NButton type="primary" attr-type="submit">Create</NButton>
                </NFlex>
              </NForm>
            </NModal>

            <NModal
              :show="state === 'settingsModal'"
              preset="card"
              title="Settings"
              class="max-w-md"
              @update:show="transitionTo('idle')"
            >
              <SettingsForm />
            </NModal>
          </NLayoutSider>
          <NLayoutContent class="min-h-screen">
            <NuxtPage />
          </NLayoutContent>
        </NLayout>
      </NDialogProvider>
    </NMessageProvider>
  </NaiveConfigProvider>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { SpeedInsights } from '@vercel/speed-insights/nuxt'
import {
  NLayoutContent,
  NLayoutSider,
  NLayout,
  NMenu,
  NButton,
  NFlex,
  NForm,
  NModal,
  NInput,
  NFormItem,
  NMessageProvider,
  NDialogProvider,
} from 'naive-ui'

import { NuxtLoadingIndicator, NuxtPage } from '#components'
import { useI18n } from '#i18n'
import {
  navigateTo,
  useAsyncData,
  useRoute,
  refreshNuxtData,
  useFeatureFlag,
} from '#imports'

import type { MenuGroupOption, MenuOption, FormInst } from 'naive-ui'

import { isDocument } from '@/client-api/schema'
import BrandDisplay from '@/components/BrandDisplay.vue'
import NaiveConfigProvider from '@/components/NaiveConfigProvider.vue'
import SettingsForm from '@/components/SettingsForm.vue'
import WhatsNewDialog from '@/components/WhatsNewDialog.vue'
import { useAPI } from '@/composables/useAPI'
import { useColorMode } from '@/composables/useColorMode'
import { renderIcon, renderLink } from '@/utils/ui'

useColorMode()

const route = useRoute()
const api = useAPI()
const { t } = useI18n()
const applicationsFeatureEnabled = useFeatureFlag('apps')

const { data } = await useAsyncData(async () => {
  const [applications, resources] = await Promise.all([
    api.Applications.all(),
    api.Resources.all(),
  ])

  return {
    applications: applications.rows,
    resources: resources.rows,
  }
})

const activeMenuOption = computed(() => {
  if (route.params.slug) {
    return String(route.params.slug)
  }

  if (route.params.id) {
    return String(route.params.id)
  }

  if (route.name === 'applications') {
    return 'applications:all'
  }
  return undefined
})

const menuOptions = computed<MenuOption[]>(() => {
  return [
    {
      label: t('menu.applications'),
      key: 'applications',
      show: applicationsFeatureEnabled.value,
      icon: renderIcon('laptop-mobile'),
      children: [
        {
          label: renderLink(t('menu.viewAll'), {
            to: { name: 'applications' },
          }),
          key: 'applications:all',
          icon: renderIcon('magnifying-glass'),
        },
        {
          label: t('menu.new'),
          key: 'applications:new',
          icon: renderIcon('plus'),
        },
        {
          type: 'group',
          show: !!data.value?.applications.length,
          label: t('menu.myApplications'),
          key: 'my-applications',
          children: data.value?.applications.map((app) => {
            return {
              label: renderLink(app.doc?.label || '', {
                to: {
                  name: 'applications-slug-details',
                  params: { slug: app.doc?.slug || '' },
                },
              }),
              key: app.doc?.slug,
            }
          }),
        },
      ],
    },
    {
      label: t('menu.resources'),
      key: 'resources',
      icon: renderIcon('folder'),
      children: Object.values(
        data.value?.resources.reduce<Record<string, MenuGroupOption>>(
          (groups, resource) => {
            if (!resource.doc) {
              return groups
            }

            if (!(resource.doc.type in groups)) {
              groups[resource.doc.type] = {
                type: 'group',
                label: resource.doc.type,
                key: resource.doc.type,
                children: [],
              }
            }

            groups[resource.doc.type].children.push({
              label: renderLink(resource.doc.label || '', {
                to: {
                  name: 'resources-id-build-questions',
                  params: { id: resource.doc.legacyStorageKey }, // TODO update to doc.legacyId
                },
              }),
              key: resource.doc.legacyStorageKey || resource.doc.slug,
            })

            return groups
          },
          {} as Record<string, MenuGroupOption>,
        ) || {},
      ),
    },
    {
      label: renderLink(
        t('menu.docs'),
        {
          href: 'https://docs.visiontree.com/',
          target: '_blank',
        },
        { disableEllipsis: true },
      ),
      icon: renderIcon('book'),
      key: 'docs',
      extra: '↗',
    },
    {
      label: t('menu.settings'),
      icon: renderIcon('gear'),
      key: 'settings',
    },
  ]
})

type State = 'idle' | 'newApplicationModal' | 'settingsModal'
const state = ref<State>('idle')
const navIsCollapsed = ref(
  window.localStorage.getItem('mfx-studio:mainNav') === 'collapsed',
)
const newApp = ref({
  label: '',
})

const newAppFormRef = ref<FormInst>()

const newAppFormRules = {
  label: {
    required: true,
    message: 'Name is required',
    trigger: 'blur',
  },
}

const transitionTo = (nextState: State) => {
  state.value = nextState
}

const createNewApplicationAndRedirect = async () => {
  if (!newAppFormRef.value) {
    return
  }

  await newAppFormRef.value.validate()

  const newApplication = await api.Applications.create({
    label: newApp.value.label,
    resources: [],
    dependencies: {},
  })

  await navigateTo({
    name: 'applications-slug-details',
    params: { slug: newApplication.slug },
  })

  transitionTo('idle')
}

const performMenuAction = (itemKey: string) => {
  switch (itemKey) {
    case 'applications:new': {
      state.value = 'newApplicationModal'
      break
    }

    case 'settings': {
      state.value = 'settingsModal'
      break
    }
  }
}

watch(navIsCollapsed, (value) => {
  window.localStorage.setItem(
    'mfx-studio:mainNav',
    value ? 'collapsed' : 'expanded',
  )
})

void api.$$database
  .changes({
    since: 'now',
    live: true,
    filter: (document) => {
      return (
        isDocument(document) &&
        document._id.match(/^((Resources)|(Applications))_/)
      )
    },
  })
  .on('change', async () => {
    await refreshNuxtData()
  })
</script>
